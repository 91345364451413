$(function() {

    $('.page-administrace-uzivatele').each(function() {
        // checkbox s zobrazujici dalsi prvky
        $('input[name="zmenit_heslo"]').each(function() {
            var form = $(this).parents('form');
            var parent = $('.heslo', form);
            if(parent.size() > 0) {
                $(this).change(function(){
                    if($(this).is(":checked")) {
                        $('input', parent).removeAttr('disabled');
                        $('input', parent).each(function() {
                            if($(this).val() == "") {
                                $(this).val($(this).data("value"));
                            }
                        });
                    }
                    else {
                        $('input', parent).attr('disabled', 'disabled');
                        $('input', parent).each(function() {
                            $(this).data("value", $(this).val()).val("");
                        });
                    }
                });
                $(this).trigger('change');
            }
        });
    });

});