function parseNum(num) {
    var num = parseFloat(num.replace(',', '.').replace(' ', ''));
    return isNaN(num) ? 0 : num;
}

function format(num, des) {
    num = num.toFixed(des);
    num = isNaN(num) ? '' : num;
    return num.replace('.', ',');
}

function parseVypocet(vypocet) {
    vypocet = vypocet.replace(',', '.').replace(' ', '').replace(/^([^\*\/])/, '*$1');
    var nums = vypocet.split(/[\*\/]/);
    var ops = vypocet.split(/[^\*\/]+/);
    nums.shift();

    var num = 1;
    for (i in nums) {
        switch(ops[i]) {
            case '*':
                num = num * parseFloat(nums[i]);
                break;
            case '/':
                num = num / parseFloat(nums[i]);
                break;
        }
    }
    return num;
}