$(function() {

    $ ('.page-evidence-papir').each (function () {
        var context = $ (this);

        $('#frm-papir-form input[name="cena_celkem"]', context).attr('readonly', true);

        $('#frm-papir-form input[name="gramaz"], #frm-papir-form input[name="format"], #frm-papir-form input[name="mnozstvi"], #frm-papir-form input[name="cena"]', context).keyup(function(event) {
            var cena = $('#frm-papir-form input[name="cena"]', context).val().replace(/,/g, '.');
            var gramaz = $('#frm-papir-form input[name="gramaz"]', context).val();
            var format = $('#frm-papir-form input[name="format"]', context).val().replace(/,/g, '.');
            var mnozstvi = $('#frm-papir-form input[name="mnozstvi"]', context).val();

            console.log(format);
            if (cena.length > 0 && gramaz.length > 0 && format.length > 0 && (/[0-9]+\.?[0-9]*[\D*][0-9]+\.?[0-9]*/i).test(format) && mnozstvi.length > 0) {
                var parts = format.match(/^([0-9]+\.?[0-9]*)[\D*]([0-9]+\.?[0-9]*)$/i);
                console.log(parts);
                var celkem = (((parts[1] / 100) * (parts[2] / 100) * gramaz * mnozstvi) / 1000) * parseFloat(cena);

                $('#frm-papir-form input[name="cena_celkem"]', context).val(Math.ceil(celkem));
            }

        });
    });

});