var chat = {

    // data holds variables for use in the class:
    data: {
        lastID: 0,
        noActivity: 0,
        name: $('.chat-container').data('login')
    },

    // Init binds event listeners and sets up timers:
    init: function() {
        // We use the working variable to prevent
        // multiple form submissions:
        var working = false;

        // Submitting a new chat entry:
        $('.submit-form').submit(function() {

            var text = $('.chat-text').val();

            if (text.length === 0){
                return false;
            }

            if (working) {
                return false;
            }
            working = true;

            // Assigning a temporary ID to the chat:
            var tempID = 't' + Math.round(Math.random()*1000000),
                params = {
                    id: tempID,
                    author: chat.data.name,
                    text: text.replace(/</g, '&lt;').replace(/>/g, '&gt;')
                };

            // Using our addChatLine method to add the chat
            // to the screen immediately, without waiting for
            // the AJAX request to complete:
            chat.addChatLine($.extend({}, params));

            $.post('submit-chat', $(this).serialize(), function(r) {
                working = false;

                $('.chat-text').val('');
                $('div.message-' + tempID).remove();

                params['id'] = r.insertID;
                chat.addChatLine($.extend({}, params));
            }, 'json');

            return false;
        });

        $('.chat-text').focus();

        // Self executing timeout functions
        (function getChatsTimeoutFunction(){
            chat.getChats(getChatsTimeoutFunction);
        })();

        (function getUsersTimeoutFunction(){
            chat.getUsers(getUsersTimeoutFunction);
        })();

    },

    // The render method generates the HTML markup
    // that is needed by the other methods:
    render: function(template, params){
        var arr = [];
        switch(template){
            case 'chatLine':
                arr = [
                    '<div class="message message-', params.id, '">',
                    '<div class="message-content">',
                    '<div>',
                    '<span class="author">', params.author, '</span>',
                    '<span class="time">', params.time, '</span>',
                    '</div>',
                    '<div><span class="text">', params.text, '</span></div>',
                    '</div>',
                    '<div class="clear"></div>',
                    '</div>'
                ];
                break;
            case 'user':
                arr = [
                    '<div class="user" title="',params.name,'">',
                    params.name,
                    '</div>'
                ];
                break;
        }

        // A single array join is faster than
        // multiple concatenations
        return arr.join('');

    },

    // The addChatLine method ads a chat entry to the page
    addChatLine: function(params) {

        // All times are displayed in the user's timezone
        var d = new Date();
        if(params.time) {
            d.setHours(params.time.hours, params.time.minutes, params.time.seconds);
        }

        params.time = (d.getHours() < 10 ? '0' : '' ) + d.getHours() + ':' + (d.getMinutes() < 10 ? '0': '') + d.getMinutes() + ':' + (d.getSeconds() < 10 ? '0': '') + d.getSeconds();

        var markup = chat.render('chatLine', params);
        var exists = $('.chat-messages .message-' + params.id);

        if(exists.length){
            exists.remove();
        }

        if(!chat.data.lastID){
            // If this is the first chat, remove the
            // paragraph saying there aren't any:
            $('.chat-messages p').remove();
        }

        // If this isn't a temporary chat:
        if(params.id.toString().charAt(0) !== 't'){
            var previous = $('.chat-messages .message-' + (+params.id - 1));
            if(previous.length){
                previous.after(markup);
            } else {
                $('.chat-messages').append(markup);
            }
        } else {
            $('.chat-messages').append(markup);
        }

        $('.chat-messages').scrollTop($('.chat-messages')[0].scrollHeight);
    },

    // This method requests the latest chats
    // (since lastID), and adds them to the page.
    getChats: function(callback) {
        $.get('get-chats', { lastID: chat.data.lastID }, function(r) {

            for (var i = 0; i < r.messages.length; i++) {
                chat.addChatLine(r.messages[i]);
            }

            if (r.messages.length){
                chat.data.noActivity = 0;
                chat.data.lastID = r.messages[i-1].id;
            } else {
                // If no chats were received, increment
                // the noActivity counter.
                chat.data.noActivity++;
            }

            if (!chat.data.lastID) {
                $('.chat-messages').html('<p class="no-messages">Zatím žádné zprávy</p>');
            }

            // Setting a timeout for the next request,
            // depending on the chat activity:
            var nextRequest = 1000;

            // 2 seconds
            if (chat.data.noActivity > 3) {
                nextRequest = 2000;
            }

            if (chat.data.noActivity > 10) {
                nextRequest = 5000;
            }

            // 15 seconds
            if (chat.data.noActivity > 20) {
                nextRequest = 15000;
            }

            setTimeout(callback, nextRequest);
        }, 'json');
    },

    // Requesting a list with all the users.
    getUsers: function(callback) {
        $.get('get-users', function(r) {
            var users = [];

            for (var i = 0; i < r.users.length; i++){
                if (r.users[i]) {
                    users.push(chat.render('user', r.users[i]));
                }
            }

            var message = '';

            if (r.total<1) {
                message = 'Nikdo není online';
            } else {
                message = r.total + ' ' + (r.total === 1 ? 'uživatel': 'uživatelé') + ' online';
            }

            users.push('<p class="count">' + message + '</p>');

            $('.chat-users').html(users.join(''));

            setTimeout(callback,15000);
        }, 'json');
    },
};
