$(function() {

    $('.datepicker, .grido .filter .date').each(function(index, input) {
        $(input).dateRangePicker({
            autoClose: true,
            format: "DD.MM.YYYY",
            language: "cz",
            separator: " - ",
            startOfWeek: "monday",
            singleDate: true,
            showShortcuts: false,
            singleMonth: true
        });
    })

});