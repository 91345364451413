$(function(){

    $('body').on('click', '.grido .actions a[data-grido-confirm]', function(e) {
        if(!confirm($(this).data('grido-confirm'))) {
            e.stopPropagation();
            e.preventDefault();
        }
    });

    $('.icon-collapse').bind('status', function(e, status) {
        $(this).removeClass('icon-open icon-close icon-loading');
        var option = $(this).data("options");
        option.status = status;
        $(this).data("options", option);
        $(this).addClass('icon-' + option.status);
    });

    $('.icon-collapse').click(function() {
        var collapse = $(this);
        var option = collapse.data("options");
        switch(option.status) {
            case 'close':
                collapse.trigger('status', 'loading');
                option.xhr = $.ajax({
                    type: "POST",
                    url: option.href
                })
                .fail(function() {
                    collapse.trigger('status', 'close');
                })
                .done(function( rows ) {
                    option.rows = $(rows);
                    collapse.data('options', option);
                    collapse.parents('tr:first').after(option.rows);
                    collapse.trigger('status', 'open');
                });
                break;
            case 'loading':
                option.xhr.abort();
            case 'open':
                collapse.trigger('status', 'close');
                if(option.rows) {
                    option.rows.remove();
                }
                break;
        }

    });

    $('.btn.disabled').each(function() {
        $(this).removeAttr('href');
        $(this).click(function(e){
            e.preventDefault();
            e.stopPropagation();
        });
    });

    // checkbox s poznamkou
    $(':checkbox:not(:disabled)').each(function(){
        var parent = $(this).parent();
        var note = $('.note', parent);
        var input = $(':checkbox', parent);
        if(note.data('notes')) {
            input.change(function(){
                var i = input.is(":checked") ? 1 : 0;
                note.html(note.data('notes')[i]);
            });
            input.trigger('change');
        }
    });

    // checkbox s zobrazujici dalsi prvky
    $(':checkbox:not(:disabled)').each(function() {
        var parent = $(this).parent();
        var detail = $('.detail', parent);
        var input = $(':checkbox', parent);
        if(detail.size() > 0) {
            input.change(function(){
                if(input.is(":checked")) {
                    $('input', detail).removeAttr('disabled');
                    $(':input', detail).each(function() {
                        if($(this).val() == "") {
                            $(this).val($(this).data("value"));
                        }
                    });
                    $('select', detail).removeAttr('readonly');
                }
                else {
                    $('input', detail).attr('disabled', 'disabled');
                    $(':input', detail).each(function() {
                        $(this).data("value", $(this).val()).val("");
                    });
                    $('select', detail).attr('readonly', 'readonly');
                }
            });
            input.trigger('change');
        }
    });

    // https://github.com/Olicek/form-selectize
    selectize('.selectize', {
        customRender: function (options) {
            return {
                option: function(item, escape) {
                    var label = item.name || item.caption;
                    var caption = item.name ? item.caption : null;
                    return '<div>' +
                        '<div class="label">' + escape(label) + '</div>' +
                        (caption ? '<div class="caption">' + escape(caption) + '</div>' : '') +
                        '</div>';
                }
            }
        }
    });
});
