$(function() {

    $ ('.page-evidence-tisk').each (function () {
        var context = $ (this);

        $( ".sortable tbody").sortable({
            placeholder: "ui-state-highlight",
            start: function( event, ui ) {
                ui.placeholder.height(ui.item.outerHeight());
            },
            helper: function(e, tr)
            {
                var $originals = tr.children();
                var $helper = tr.clone();
                $helper.children().each(function(index)
                {
                    // Set helper cell sizes to match the original sizes
                    $(this).width($originals.eq(index).width());
                    $(this).height(tr.outerHeight());
                });
                return $helper;
            },
            update: function (e, ui) {
                var position = $('.sortable tbody tr', context).index(ui.item);
                var url = $('.grid-cell-order a', ui.item).attr('href').replace(/poradi=[0-9]+/, 'poradi=' + position);

                $.get(url);
            }
        });//.disableSelection();
    });

});