$(function() {

    $('.grido select[data-signal]').change(function() {
        var signal = $(this).data('signal');
        var value = $(this).val();
        $.getJSON(signal.replace('-value-', value), function(data) {
            if(data && data.redirect) {
                location.replace(data.redirect);
            }
        });
    });

});
