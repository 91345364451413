$(function() {

    $('.page-evidence-zakazka').each(function() {
        var context = $(this);

        var email = $('select[name="vyrizuje"]', context).data('email');
        $('select[name="vyrizuje"]', context).on('change', function() {
            $('input[name="vyrizuje_email"]', context).val(email[$(this).val()]);
        });

        var mobil = $('select[name="vyrizuje"]', context).data('mobil');
        $('select[name="vyrizuje"]', context).on('change', function() {
            $('input[name="vyrizuje_mobil"]', context).val(mobil[$(this).val()]);
        });
    });

});