$(function() {

    $ ('.page-evidence-ctp').each (function () {
        var context = $ (this);

        $("table tbody tr", context).dblclick(function(e) {
            if ($(".actions .grid-action-edit", this).length > 0) {
                window.location.href = $(".actions .grid-action-edit", this).attr("href");
            }
        });

    });

});