$(function() {

    $('.page-evidence-technologie').each(function() {
        var context = $(this);
        var pocetCelkem = $('input[name=pocet_kusu]', context);

        // change pocet vyrobku
        pocetCelkem.change(function() {
            var pocet = format(parseNum($(this).val()), 0);
            $(this).val(pocet);
            $('.pocet').each(function(i, e) {
                if(!$(e).is('[disabled]')) {
                    $(e).val(pocet).change();
                }
            });
        });

        // change technologie checkboxy
        $('.technologie :checkbox', context).each(function() {
            var parent = $(this).parents('tr:first');
            var checkbox = $(':checkbox', parent);
            var pocet = $('.pocet', parent);
            var hodiny = $('.hodiny', parent);
            checkbox.change(function() {
                if(checkbox.is(':checked')) {
                    pocet.val(pocetCelkem.val()).change();
                    pocet.removeAttr('disabled');
                    hodiny.removeAttr('disabled');
                }
                else {
                    pocet.val('').change();
                    pocet.val('');
                    hodiny.val('');
                    pocet.attr('disabled', 'disabled');
                    hodiny.attr('disabled', 'disabled');
                }
            });
        });

        // change technologie pocet
        $('.technologie', context).on('change', '.pocet', function() {
            var parent = $(this).parents('tr:first');
            var pocet = $('.pocet', parent);
            var norma = $('.norma', parent);
            var hodiny = $('.hodiny', parent);
            pocet.val(format(parseNum(pocet.val()), 0));
            hodiny.val(format(pocet.val() * parseVypocet(norma.val()), 2));
            hodiny.change();
        });

        // change technologie hodiny
        $('.technologie', context).on('change', '.hodiny', function() {
            var suma = 0;
            $('.technologie .hodiny', context).each(function() {
                suma += parseNum($(this).val());
            });
            $('.technologie .suma', context).val(format(suma, 2)).change();
        });

        // change technologie suma
        $('.technologie', context).on('change', '.suma', function() {
            $('.sumarizace span.technologie', context).text(format(parseNum($(this).val()), 2)).change();
            $('.sumarizace input.technologie', context).val(format(parseNum($(this).val()), 2)).change();
            if ($('.sumarizace input[name="suma_technologie"]', context).val().length == 0) {
                $('.sumarizace input[name="suma_technologie"]', context).val(format(parseNum($(this).val()), 2)).change();
            }
        });

        // change ukony tisk archy
        $('.ukony', context).on('change', '.tisk.archy', function() {
            var parent = $(this).parents('tr:first');
            var archy = $('.tisk.archy', parent);
            var norma = $('.tisk.norma', parent);
            var hodiny = $('.tisk.hodiny', parent);
            archy.val(format(parseNum(archy.val()), 0));
            hodiny.val(format(archy.val() * parseVypocet(norma.val()), 2));
            hodiny.change();
        });

        // change ukony tisk hodiny
        $('.ukony', context).on('change', '.tisk.hodiny', function() {
            var suma = 0;
            $('.ukony .tisk.hodiny', context).each(function() {
                suma += parseNum($(this).val());
            });
            $('.ukony .tisk.suma', context).val(format(suma, 2)).change();
        });

        // change ukony tisk suma
        $('.ukony', context).on('change', '.tisk.suma', function() {
            $('.sumarizace span.tisk', context).text(format(parseNum($(this).val()), 2)).change();
            $('.sumarizace input.tisk', context).val(format(parseNum($(this).val()), 2)).change();
            if ($('.sumarizace input[name="suma_tisk"]', context).val().length == 0) {
                $('.sumarizace input[name="suma_tisk"]', context).val(format(parseNum($(this).val()), 2)).change();
            }
        });

        // change ukony pripravy archy
        $('.ukony', context).on('change', '.pripravy.priprav', function() {
            var parent = $(this).parents('tr:first');
            var pripravy = $('.pripravy.priprav', parent);
            var norma = $('.pripravy.norma', parent);
            var hodiny = $('.pripravy.hodiny', parent);
            pripravy.val(format(parseNum(pripravy.val()), 0));
            hodiny.val(format(pripravy.val() * parseVypocet(norma.val()), 2));
            hodiny.change();
        });

        // change ukony pripravy hodiny
        $('.ukony', context).on('change', '.pripravy.hodiny', function() {
            var suma = 0;
            $('.ukony .pripravy.hodiny', context).each(function() {
                suma += parseNum($(this).val());
            });
            $('.ukony .pripravy.suma', context).val(format(suma, 2)).change();
        });

        // change ukony pripravy suma
        $('.ukony', context).on('change', '.pripravy.suma', function() {
            $('.sumarizace span.pripravy', context).text(format(parseNum($(this).val()), 2)).change();
            $('.sumarizace input.pripravy', context).val(format(parseNum($(this).val()), 2)).change();
            if ($('.sumarizace input[name="suma_pripravy"]', context).val().length == 0) {
                $('.sumarizace input[name="suma_pripravy"]', context).val(format(parseNum($(this).val()), 2)).change();
            }
        });

        // change ukony falcovani archy
        $('.ukony', context).on('change', '.falcovani.archy', function() {
            var parent = $(this).parents('tr:first');
            var archy = $('.falcovani.archy', parent);
            var norma = $('.falcovani.norma', parent);
            var hodiny = $('.falcovani.hodiny', parent);
            archy.val(format(parseNum(archy.val()), 0));
            hodiny.val(format(archy.val() * parseVypocet(norma.val()), 2));
            hodiny.change();
        });

        // change ukony falcovani hodiny
        $('.ukony', context).on('change', '.falcovani.hodiny', function() {
            var suma = 0;
            $('.ukony .falcovani.hodiny', context).each(function() {
                suma += parseNum($(this).val());
            });
            $('.ukony .falcovani.suma', context).val(format(suma, 2)).change();
        });

        // change ukony falcovani suma
        $('.ukony', context).on('change', '.falcovani.suma', function() {
            $('.sumarizace span.falcovani', context).text(format(parseNum($(this).val()), 2)).change();
            $('.sumarizace input.falcovani', context).val(format(parseNum($(this).val()), 2)).change();
            if ($('.sumarizace input[name="suma_falcovani"]', context).val().length == 0) {
                $('.sumarizace input[name="suma_falcovani"]', context).val(format(parseNum($(this).val()), 2)).change();
            }
        });

        // change sumarizace
        $('.sumarizace', context).on('change', '.technologie, .tisk, .pripravy, .falcovani', function() {
            var suma = 0;
            $('.sumarizace span.technologie, .sumarizace span.tisk, .sumarizace span.pripravy, .sumarizace span.falcovani', context).each(function() {
                suma += parseNum($(this).text());
            });
            $('.sumarizace span.celkem', context).text(format(suma, 2)).change();
            $('.sumarizace input.celkem', context).val(format(suma, 2)).change();
        });

        // change sumarizace
        $('.sumarizace', context).on('change', 'input[name="suma_technologie"], input[name="suma_tisk"], input[name="suma_pripravy"], input[name="suma_falcovani"]', function() {
            var suma = 0;
            $('.sumarizace input[name="suma_technologie"], .sumarizace input[name="suma_tisk"], .sumarizace input[name="suma_pripravy"], .sumarizace input[name="suma_falcovani"]', context).each(function() {
                suma += parseNum($(this).val());
            });
            $('.sumarizace input[name="suma_zpracovani_celkem"]', context).val(format(suma, 2)).change();
        });

        $('.technologie .pocet', context).each(function() {
            var parent = $(this).parents('tr:first');
            var checkbox = $(':checkbox', parent);
            var pocet = $('.pocet', parent);
            var hodiny = $('.hodiny', parent);
            if(pocet.val().length > 0) {
                checkbox.prop('checked', true);
                pocet.removeAttr('disabled');
                hodiny.removeAttr('disabled');
                pocet.change();
            }
        });

        $('.ukony .tisk.archy', context).each(function() {
            var parent = $(this).parents('tr:first');
            var archy = $('.tisk.archy', parent);
            var norma = $('.tisk.norma', parent);
            var hodiny = $('.tisk.hodiny', parent);
            if(archy.val().length > 0) {
                archy.change();
            }
        });

        $('.ukony .pripravy.priprav', context).each(function() {
            var parent = $(this).parents('tr:first');
            var pripravy = $('.pripravy.priprav', parent);
            var norma = $('.pripravy.norma', parent);
            var hodiny = $('.pripravy.hodiny', parent);
            if(pripravy.val().length > 0) {
                pripravy.change();
            }
        });

        $('.ukony .falcovani.archy', context).each(function() {
            var parent = $(this).parents('tr:first');
            var archy = $('.falcovani.archy', parent);
            var norma = $('.falcovani.norma', parent);
            var hodiny = $('.falcovani.hodiny', parent);
            if(archy.val().length > 0) {
                archy.change();
            }
        });
    });

});