$(function() {

    $('.daterangepicker, .grido .filter .daterange').each(function(index, input) {
        $(input).dateRangePicker({
            autoClose: true,
            format: "DD.MM.YYYY",
            language: "cz",
            separator: " - ",
            startOfWeek: "monday",
            singleDate: false,
            showShortcuts: true,
            singleMonth: false
        });
    })

});